/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



const FlashyButton = ({ onClick }) => {
  return (
    <button className="flashy-button" onClick={onClick}>
      Order Your card now!!!
    </button>
  );
};

const Home = ()=>{
    const [isLoad, setLoading] = useState(true);

    useEffect(() => {
      // Spinner
      var spinner = function () {
        setTimeout(function () {
          if ($('#spinner').length > 0) {
            $('#spinner').removeClass('show');
          }
        }, 1);
      };
      spinner();
  
  
      // Initiate the wowjs
      // eslint-disable-next-line no-undef
      new WOW().init();
  
  
      // Sticky Navbar
      $(window).scroll(function () {
        if ($(this).scrollTop() > 45) {
          $('.navbar').addClass('sticky-top shadow-sm');
        } else {
          $('.navbar').removeClass('sticky-top shadow-sm');
        }
      });
  
  
      // Smooth scrolling on the navbar links
      $(".navbar-nav a").on('click', function (event) {
        if (this.hash !== "") {
          event.preventDefault();
  
          $('html, body').animate({
            scrollTop: $(this.hash).offset().top - 45
          }, 1500, 'easeInOutExpo');
  
          if ($(this).parents('.navbar-nav').length) {
            $('.navbar-nav .active').removeClass('active');
            $(this).closest('a').addClass('active');
          }
        }
      });
  
  
      // Back to top button
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('.back-to-top').fadeIn('slow');
        } else {
          $('.back-to-top').fadeOut('slow');
        }
      });
      $('.back-to-top').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
        return false;
      });
  
  
      // Facts counter
      $('[data-toggle="counter-up"]').counterUp({
        delay: 10,
        time: 2000
      });
  
  
      // Screenshot carousel
      $(".screenshot-carousel").owlCarousel({
        autoplay: true,
        smartSpeed: 1000,
        loop: true,
        dots: true,
        items: 1
      });
  
  
      // Testimonials carousel
      $(".testimonial-carousel").owlCarousel({
        autoplay: true,
        smartSpeed: 1000,
        loop: true,
        center: true,
        dots: false,
        nav: true,
        navText: [
          '<i class="bi bi-chevron-left"></i>',
          '<i class="bi bi-chevron-right"></i>'
        ],
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          992: {
            items: 3
          }
        }
      });
  
  
    }, []);
    const showClass = isLoad ? "show" : "";
    const[name, setName] = useState("")
    const[email, setEmail] = useState("")
    const[subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
  
    const onNameChangeHandler = (e)=>{
      setName(e.target.value)
    }
  
    const onEmailChangeHandler = (e)=>{
      setEmail(e.target.value)
    }
  
    const onSubjectHandler = (e)=>{
      setSubject(e.target.value)
    }
  
    const onMessageHandler = (e)=>{
      setMessage(e.target.value)
    }
  
    const onSendButtonHandler = ()=>{
      console.log(name, email, subject, message,"hello")
    }
  
    const disabled = name==="" || email==="" || subject==="" || message===""
    const navigate = useNavigate()
  
    const onImageHandler = (navigatTo)=>{
      navigate(navigatTo)
    }
    return(
        <div class="container-xxl bg-white p-0">
      {/* <!-- Spinner Start --> */}
      
      <div id="spinner"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-grow text-primary" style={{ "width": "3rem", "height": "3rem" }} role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      {/* <!-- Spinner End --> */}


      {/* <!-- Navbar & Hero Start --> */}
      <div class="container-xxl position-relative p-0" id="home">
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <a href="/" class="navbar-brand p-0">
            <h1 class="m-0">Easy Rev</h1>
            {/* <!-- <img src="img/logo.png" alt="Logo"> --> */}
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav mx-auto py-0">
              <a href="#home" class="nav-item nav-link active">Home</a>
              {/* <!-- <a href="#about" class="nav-item nav-link">About</a> --> */}
              <a href="#feature" class="nav-item nav-link">Feature</a>
              <a href="#download" class="nav-item nav-link">Download</a>
              <a href="#review" class="nav-item nav-link">Review</a>
              <a href="#contact" class="nav-item nav-link">Contact</a>
            </div>
          </div>
        </nav>

        <div class="container-xxl bg-primary hero-header">
          <div class="row-hero-header-container container px-lg-5">
            <div class="row-hero-header row g-5">
              <div class="col-lg-7 text-center text-lg-start">
                <h1 class="text-white mb-4 animated slideInDown">Revolutionizing Reviews with AI<br /> Collect
                  Reviews Within Seconds !</h1>
                <p class="text-white pb-3 animated slideInDown">Discover our range of cutting-edge scanners:
                  Tap Scanner, Basic Scanner, and Pocket Scanner. Streamline your review collection
                  effortlessly. Elevate your business with Easy Revv!</p>
              <FlashyButton onClick={()=>onImageHandler("/products")} />

                {/* <!-- <a href="" class="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Read More</a>
                            <a href="" class="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight">Contact Us</a> --> */}
              </div>
              <div class="col-lg-5 d-flex justify-content-center justify-content-lg-end wow fadeInUp"
                data-wow-delay="0.5s">
                <div onClick={()=>onImageHandler("/products")} class="owl-carousel screenshot-carousel">
                  <img class="img-fluid" src="img/Pocket Scanner.png" alt="" />
                  <img class="img-fluid" src="img/Basic Scanner.png" alt="" />
                  <img class="img-fluid" src="img/Tap Scanner.png" alt="" />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Navbar & Hero End --> */}




      {/* <!-- Features Start --> */}
      <div class="container-xxl py-5" id="feature">
        <div class="container py-5 px-lg-5">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">Why Us ?</h5>
            <h1 class="mb-5">Awesome Features</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="feature-item bg-light rounded p-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"
                  style={{ "width": "60px", height: "60px" }}>
                  <i class="fas fa-user-astronaut fs-4" style={{ "color": "#ffffff" }}></i>
                </div>
                <h5 class="mb-3">AI Assisted Writing!</h5>
                <p class="m-0">AI-Powered Sentences Tailored sentances for customer to write Unique review
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="feature-item bg-light rounded p-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"
                  style={{ "width": "60px", "height": "60px" }}>
                  <i class="fa fa-clock text-white fs-4"></i>
                </div>
                <h5 class="mb-3">Easy to get Review</h5>
                <p class="m-0">Customer can give a awesome review sentences within few seconds</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="feature-item bg-light rounded p-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"
                  style={{ "width": "60px", height: "60px" }}>
                  <i class="fa fa-tag text-white fs-4"></i>
                </div>
                <h5 class="mb-3">Affordable Prices </h5>
                <p class="m-0">Budget friendly price! Costing same as a pizza! Maximum value for your
                  business!</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="feature-item bg-light rounded p-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"
                  style={{ "width": "60px", height: "60px" }}>
                  <i class="fa fa-qrcode text-white fs-4"></i>
                </div>
                <h5 class="mb-3">One QR for all Social Media</h5>
                <p class="m-0">Google, Instagram, Facebook, Swiggy, Zomato all in one QR</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="feature-item bg-light rounded p-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"
                  style={{ "width": "60px", height: "60px" }}>
                  <i class="fa fa-pen text-white fs-4"></i>
                </div>
                <h5 class="mb-3">Fully Customizable</h5>
                <p class="m-0">Our Easy Revv application helps you to customize all sorts of things</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="feature-item bg-light rounded p-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"
                  style={{ "width": "60px", height: "60px" }}>
                  <i class="fa fa-mobile-alt text-white fs-4"></i>
                </div>
                <h5 class="mb-3">No App Required</h5>
                <p class="m-0">Customer dont need any application to scan the QR and works with android and
                  iphone</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Features End --> */}


    
      {/* <!-- Process Start --> */}
      <div class="container-xxl py-3">
        <div class="container py-5 px-lg-5">
          <div class="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">How It Works</h5>
            <h1 class="mb-5">3 Easy Steps</h1>
          </div>
          <div class="row gy-5 gx-4 justify-content-center">
            <div class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
              <div class="position-relative bg-light rounded pt-5 pb-4 px-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ "width": "100px", height: "100px" }}>
                  <i class="fa fa-cog fa-3x text-white"></i>
                </div>
                <h5 class="mt-4 mb-3">Install the App</h5>
                <p class="mb-0">Install our application from google playstore</p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.3s">
              <div class="position-relative bg-light rounded pt-5 pb-4 px-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ "width": "100px", height: "100px" }}>
                  <i class="fa fa-address-card fa-3x text-white"></i>
                </div>
                <h5 class="mt-4 mb-3">Setup Your Profile</h5>
                <p class="mb-0">Setup your profile, activate your card by scanning the QR</p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
              <div class="position-relative bg-light rounded pt-5 pb-4 px-4">
                <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ "width": "100px", height: "100px" }}>
                  <i class="fa fa-check fa-3x text-white"></i>
                </div>
                <h5 class="mt-4 mb-3">Enjoy The Features</h5>
                <p class="mb-0">Get Statistics about the profile, scans and many more</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Process Start --> */}


      {/* <!-- Download Start --> */}
      <div class="container-xxl py-5" id="download">
        <div class="container py-5 px-lg-5">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <img class="img-fluid wow fadeInUp" data-wow-delay="0.1s" src="img/EasyRev Mobile Gif.gif" alt='' />
            </div>
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h5 class="text-primary-gradient fw-medium">Download</h5>
              <h1 class="mb-4">Download The Latest Version Of Our App</h1>
              <p class="mb-4">Download the latest version of our app to instantly elevate your user
                experience. Uncover a world of improved features and unparalleled satisfaction with just one
                click.</p>
              <div class="row g-4">
                {/* <!-- <div class="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                <a href="" class="d-flex bg-primary-gradient rounded py-3 px-4">
                                    <i class="fab fa-apple fa-3x text-white flex-shrink-0"></i>
                                    <div class="ms-3">
                                        <p class="text-white mb-0">Available On</p>
                                        <h5 class="text-white mb-0">App Store</h5>
                                    </div>
                                </a>
                            </div> --> */}
                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                  <a href="https://play.google.com/store/apps/details?id=com.easyrev&pcampaignid=web_share"
                    target="_blank" class="d-flex bg-secondary-gradient rounded py-2 px-4" rel='noreferrer' >
                    <i class="fab fa-android fa-3x text-white flex-shrink-0"></i>
                    <div class="ms-3">
                      <p class="text-white mb-0">Available On</p>
                      <h5 class="text-white mb-0">Play Store</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Download End --> */}


      {/* <!-- Testimonial Start --> */}
      <div class="container-xxl py-3" id="review">
        <div class="container py-5 px-lg-5">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">Testimonial</h5>
            <h1 class="mb-5">What Our Clients Say!</h1>
          </div>

          <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
            <div class="testimonial-item rounded p-4">
              <div class="d-flex align-items-center mb-4">
                <img class="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/DrLathaShekar.png" alt=''
                  style={{ "width": "85px", height: "85px" }} />
                <div class="ms-4">
                  <h5 class="mb-1">Dr LathaShekar</h5>
                  <p class="mb-1">Yoga Therapist | Author</p>
                  <div>
                    <small class="fa fa-star text-warning"></small>
                    <small class="fa fa-star text-warning"></small>
                    <small class="fa fa-star text-warning"></small>
                    <small class="fa fa-star text-warning"></small>
                    <small class="fa fa-star text-warning"></small>
                  </div>
                </div>
              </div>
              <p class="mb-0">Easy to setup and easy to use, Great product. As they claim it is really takes
                Only few seconds to get review ! A must-have for businesses aiming at superior customer
                engagement!</p>
            </div>
            <div class="testimonial-item rounded p-4">
              <div class="d-flex align-items-center mb-4">
                <img class="img-fluid bg-white rounded flex-shrink-0 p-1"
                  src="img/ShreeKrishnaFarmNDNursery.png" style={{ "width": "85px", height: "85px" }} />
                <div class="ms-4">
                  <h5 class="mb-1">Shree Krishna Farm & Nursery</h5>

                <p class="mb-1">Horticultural Sales</p>
                <div>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                </div>
              </div>
            </div>
            <p class="mb-0">AI-powered sentences, tailored to customer preferences, make review collection
              seamless and insightful. Highly recommended for businesses seeking enhanced customer
              engagement!</p>
          </div>
          <div class="testimonial-item rounded p-4">
            <div class="d-flex align-items-center mb-4">
              <img class="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/CodenTechnologies.png" alt=''
                style={{ "width": "85px", height: "85px" }} />
              <div class="ms-4">
                <h5 class="mb-1">Coden Technologies</h5>
                <p class="mb-1">Software Company</p>
                <div>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                </div>
              </div>
            </div>
            <p class="mb-0">Thrilled to be aligned with Easy Revv The innovative technology, especially the
              AI-powered sentences, not only streamlines review collection but also elevates customer
              engagement to new heights.</p>
          </div>
          <div class="testimonial-item rounded p-4">
            <div class="d-flex align-items-center mb-4">
              <img class="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/Chathur Medical.png" alt=''
                style={{ "width": "85px", height: "85px" }} />
              <div class="ms-4">
                <h5 class="mb-1">Chathur Medical</h5>
                <p class="mb-1">Medical Store</p>
                <div>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                  <small class="fa fa-star text-warning"></small>
                </div>
              </div>
            </div>
            <p class="mb-0">Unmatched brilliance! Easy Revv's Tap Scanner is a revelation. AI sentences
              transform reviews effortlessly, ensuring businesses thrive with superior customer engagement
            </p>
          </div>
        </div>
      </div>
      </div>
      {/* <!-- Testimonial End --> */}


      {/* <!-- Contact Start --> */}
      {/* <div class="container-xxl py-5" id="contact">
        <div class="container py-5 px-lg-5">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 class="text-primary-gradient fw-medium">Contact Us</h5>
            <h1 class="mb-5">Get In Touch!</h1>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="wow fadeInUp" data-wow-delay="0.3s">
                  <div class="row g-3">
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input onChange={onNameChangeHandler} value={name} type="text" class="form-control" id="name" placeholder="Your Name" />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input onChange={onEmailChangeHandler} value={email} type="email" class="form-control" id="email"
                          placeholder="Your Email" />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <input onChange={onSubjectHandler} value={subject} type="text" class="form-control" id="subject" placeholder="Subject" />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <textarea onChange={onMessageHandler} value={message} class="form-control" placeholder="Leave a message here"
                          id="message" maxLength={400} style={{ "height": "150px" }}></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <button disabled={disabled} onClick={onSendButtonHandler} class="btn btn-primary-gradient rounded-pill py-2 px-5"
                        >Send Message</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Contact End --> */}


      {/* <!-- Footer Start --> */}
      <div class="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5 px-lg-5">
          <div class="row g-5">
            <div class="col-md-6 col-lg-4">
              <h4 class="text-white mb-4">Address</h4>
              <p><i class="fa fa-map-marker-alt me-3"></i>C- 904 Uttarahalli, Bangalore</p>
              <p><i class="fa fa-phone-alt me-3"></i>+91 8277740015</p>
              <p><i class="fa fa-envelope me-3"></i>contactus@codentechnologies.com</p>
              {/* <!-- <div class="d-flex pt-2">
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                        </div> --> */}
            </div>
            <div class="col-md-6 col-lg-3">
              <h4 class="text-white mb-4">Quick Link</h4>
              <a class="btn btn-link" target="_blank" href="https://wa.link/fxe8xj">Contact Us</a>
              <a class="btn btn-link" href="/privacy">Privacy Policy</a>
              <a class="btn btn-link" href="terms">Terms & Condition</a>
              <a class="btn btn-link" href="refund">Redund Policy</a>
            </div>
            {/* <!-- <div class="col-md-6 col-lg-3">
                        <h4 class="text-white mb-4">Popular Link</h4>
                        <a class="btn btn-link" href="">About Us</a>
                        <a class="btn btn-link" href="">Contact Us</a>
                        <a class="btn btn-link" href="">Privacy Policy</a>
                        <a class="btn btn-link" href="">Terms & Condition</a>
                        <a class="btn btn-link" href="">Career</a>
                    </div> --> */}
            {/* <!-- <div class="col-md-6 col-lg-3">
                        <h4 class="text-white mb-4">Newsletter</h4>
                        <p>1</p>
                        <div class="position-relative w-100 mt-3">
                            <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                                placeholder="Your Email" style="height: 48px;">
                            <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                    class="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                        </div>
                    </div> --> */}
          </div>
        </div>
        <div class="container px-lg-5">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <a class="border-bottom" href="#">Easy Revv</a>, All Right Reserved.
              </div>
              <div class="col-md-6 text-center text-md-end">
                <div class="footer-menu">
                  <a href="#home">Home</a>
                  <a href="#feature">Feature</a>
                  <a href="">Products</a>
                  <a href="">FAQs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}


      {/* <!-- Back to Top --> */}
      <a href="#" class="btn btn-lg btn-lg-square back-to-top pt-2"><i class="bi bi-arrow-up text-white"></i></a>
    </div >
    )
}

export default Home;