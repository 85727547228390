import React, { useEffect, useState } from 'react'
import "./product.css"
import { useParams } from 'react-router-dom'
import { products } from '../../constants/data';
import ProductOne from "../../assets/productOne.jpg"
import { COLORS } from '../../themes/colors';
import Button from '../../components/Button';
import ProductTile from '../../components/productTile';
import axios from 'axios';
import Modal from 'react-modal';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Product = () => {
  const [products, setProducts] = useState([])
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const getProducts = async () => {
    const url = process.env.REACT_APP_BASE_URL
    const res = await axios.get(`https://api.easy-revv.com/api/getAllProducts`)
    setProducts(res.data)
  }
  useEffect(() => {
    getProducts()

  }, [])


  const onBuy = (itemId, amazonLink) => {
    // setIsOpen(true);
    console.log(itemId)
    setProductId(itemId)
    window.open(amazonLink, '_blank');
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-65%, -50%)',
    },
  };
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [productId, setProductId] = useState('');

  const handleNameChange = (event) => {
    setCustomerName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // You can perform further actions with the captured data here
    console.log('Customer Name:', customerName);
    console.log('Phone Number:', phoneNumber);
    console.log(productId, 'product')
    const url = process.env.REACT_APP_BASE_URL
    try {
      const orderData = await axios.post(`${url}/api/order`, {
        "number": phoneNumber,
        "productId": productId,
        "name": customerName
      })
      toast('📞  Order Received, We would reach you within 24 hours for processing your request', {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        autoClose: 10000,
        transition: Bounce,
        });
    } catch (error) {
      console.log(error)
    } finally {
      // Reset the form after submission
      setCustomerName('');
      setPhoneNumber('');
      setProductId('')
      closeModal()
    }


  };

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <label className='label-order'>
            Customer Name:
          </label>
          <div>
            <input className='input-order' type="text" value={customerName} onChange={handleNameChange} />
          </div>
          <br />
          <label className='label-order'>
            Phone Number:
          </label>

          <div>
            <input className='input-order' type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} />
          </div>
          <br />
          <Button onClick={handleSubmit} title="order" />
        </div>
      </Modal>
      <div class="container-xxl position-relative p-0" id="home">
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <a href="/" class="navbar-brand p-0">
            <h1 class="m-0">Easy Rev</h1>
            {/* <!-- <img src="img/logo.png" alt="Logo"> --> */}
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav mx-auto py-0">
              <a href="/" class="nav-item nav-link active">Home</a>
              {/* <!-- <a href="#about" class="nav-item nav-link">About</a> --> */}
              <a href="/#feature" class="nav-item nav-link">Feature</a>
              <a href="/#download" class="nav-item nav-link">Download</a>
              <a href="/#review" class="nav-item nav-link">Review</a>
              <a href="/#contact" class="nav-item nav-link">Contact</a>
            </div>
          </div>
        </nav>

        <div class="container-xxl bg-primary hero-header">
          <div class="row-hero-header-container container px-lg-5">
            <div class="row-hero-header row g-5">
              <div class="col-lg-7 text-center text-lg-start">
                <h1 class="text-white animated slideInDown" style={{ marginBottom: '9.6rem' }}>Revolutionizing Reviews with AI<br /> Collect
                  Reviews Within Seconds !</h1>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white', marginTop: '-50px' }}>
          {products.map((ele, index) => (<ProductTile key={ele._id} {...ele} className={(index + 1) % 2 === 0 ? "productDisplay" : "productDisplayReverse"} onBuy={onBuy} />))}
        </div>
      </div>
    </div>
  )
} 

export default Product