import React, { useEffect } from 'react';
import styles from "./RefundCancelation.module.css";
import Footer from '../../components/Footer';
import { COLORS } from '../../themes/colors';
import { useNavigate } from 'react-router-dom';

const RefundCancelation = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);
  return (
    <>
      <div className={styles.setup}>
      <div className={styles.headerLinks} onClick={() => navigate("/")}>
          <h2 style={{
            color : COLORS.TEXTCOLOR
          }} >Go to Home</h2>
        </div>
        <h1>Refund and Cancellation Policy</h1>
        <h2>Refunds</h2>
        <p>We value your satisfaction with our products. However, please note that we do not accept returns or offer refunds for products unless there is a manufacturer's defect in the item. If you believe that you have received a product with a manufacturing defect, please contact us immediately.</p>

        <h2>Return Process for Defective Products</h2>
        <p>To initiate a return for a defective product, please follow these steps:</p>
        <ol>
          <li>Contact our customer support team within three (3) business days of receiving the product to report the issue.</li>
          <li>Our team will guide you through the return process, which may include providing photographic evidence of the defect or other information as requested.</li>
          <li>If we determine that the product has a manufacturing defect, we will provide you with instructions on how to return the item.</li>
        </ol>
        <p>Please note that the following conditions apply to return requests for defective products:</p>
        <ul>
          <li>The item must be unused and in the same condition as when you received it.</li>
          <li>The item must be in its original packaging.</li>
          <li>To complete your return, we will require a receipt or proof of purchase.</li>
        </ul>
        <p>Once we receive and inspect the returned item, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment.</p>

        <h2>Non-Returnable Items</h2>
        <p>Please be aware that we do not accept returns or offer refunds for the following:</p>
        <ul>
          <li>Any software solutions or services related to the product, as these are considered non-returnable and non-refundable.</li>
          <li>Sale items. Only regular-priced items are eligible for refunds.</li>
        </ul>

        <h2>Cancellation Policy</h2>
        <p>We understand that circumstances may change, and you may need to cancel an order. If you wish to cancel an order, please contact our customer support team as soon as possible.</p>
        <p>Please note the following regarding order cancellations:</p>
        <ul>
          <li>Orders can only be cancelled before they are shipped.</li>
          <li>Once an order has been shipped, it cannot be cancelled.</li>
          <li>If an order is cancelled before shipping, a refund will be issued to the original method of payment.</li>
        </ul>

        <h2>Gift Returns</h2>
        <p>If the item in question was marked as a gift when purchased and shipped directly to you, you will receive a gift credit for the value of your return. Gift credits are non-transferable and can only be used for future purchases on our website.</p></div>
        <Footer />
    </>
  )
}

export default RefundCancelation