/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Product from './pages/product/Product';
import Home from './Home';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import TermsAndCondition from './pages/termscondition/TermsAndCondition';
import RefundCancelation from './pages/redundCancelation/RefundCancelation';

const App = () => {
 
  return (
    <BrowserRouter>
       <Routes>
         <Route path="/" element={<Home />} />
        <Route exact path='/products' element={<Product/>}/>
        <Route exact path='/privacy' element={<PrivacyPolicy/>}/>
        <Route exact path='/terms' element={<TermsAndCondition />} />
        <Route exact path='/refund' element={<RefundCancelation />} />
      </Routes>
     </BrowserRouter>
    
  )
}

export default App