import React from 'react'
import styles from "./styles/footer.module.css"
import { COLORS } from '../themes/colors'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()
    const handleNavigate = (navigatTo) => {
        navigate(navigatTo)
    }
    return (
        <footer className={styles.footerContainer} style={{
            // backgroundColor: COLORS.PRIMARY
        }}>
            <div class="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5 px-lg-5">
          <div class="row g-5">
            <div class="col-md-6 col-lg-4">
              <h4 class="text-white mb-4">Address</h4>
              <p><i class="fa fa-map-marker-alt me-3"></i>C- 904 Uttarahalli, Bangalore</p>
              <p><i class="fa fa-phone-alt me-3"></i>+91 8277740015</p>
              <a class="fa fa-envelope me-3" href="mailto:contactus@codentechnologies.com"> contactus@codentechnologies.com</a>
              {/* <!-- <div class="d-flex pt-2">
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                        </div> --> */}
            </div>
            <div class="col-md-6 col-lg-3">
              <h4 class="text-white mb-4">Quick Link</h4>
              <a class="btn btn-link" target="_blank" href="https://wa.link/fxe8xj">Contact Us</a>
              <a class="btn btn-link" href="/privacy">Privacy Policy</a>
              <a class="btn btn-link" href="/terms">Terms & Condition</a>
              <a class="btn btn-link" href="/refund">Redund Policy</a>
            </div>
            {/* <!-- <div class="col-md-6 col-lg-3">
                        <h4 class="text-white mb-4">Popular Link</h4>
                        <a class="btn btn-link" href="">About Us</a>
                        <a class="btn btn-link" href="">Contact Us</a>
                        <a class="btn btn-link" href="">Privacy Policy</a>
                        <a class="btn btn-link" href="">Terms & Condition</a>
                        <a class="btn btn-link" href="">Career</a>
                    </div> --> */}
            {/* <!-- <div class="col-md-6 col-lg-3">
                        <h4 class="text-white mb-4">Newsletter</h4>
                        <p>1</p>
                        <div class="position-relative w-100 mt-3">
                            <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                                placeholder="Your Email" style="height: 48px;">
                            <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                    class="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                        </div>
                    </div> --> */}
          </div>
        </div>
        <div class="container px-lg-5">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <a class="border-bottom" href="/">Easy Revv</a>, All Right Reserved.
              </div>
              <div class="col-md-6 text-center text-md-end">
                <div class="footer-menu">
                  <a href="/">Home</a>
                  <a href="/#feature">Feature</a>
                  <a href="/products">Products</a>
                  {/* <a href="">FAQs</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </footer>
    )
}

export default Footer