import React, { useEffect } from 'react';
import styles from "./PrivacyPolicy.module.css";
import Footer from '../../components/Footer';
import { COLORS } from '../../themes/colors';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);
  return (
    <>
      <div className={styles.setup} onClick={() => navigate("/")}>
        <div className={styles.headerLinks}>
          <h2 style={{
            color: COLORS.TEXTCOLOR
          }} >Go to Home</h2>
        </div>
        <h1>Privacy Policy</h1>
        <p><strong>Last Updated:</strong> 05-10-2022</p>

        <p>Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and protect the personal information you provide to us during the payment process and when using our products and services.</p>

        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of personal information when you use our services:</p>
        <h3>1.1. Personal Identification Information:</h3>
        <ul>
          <li>Name</li>
          <li>Business Name (if applicable)</li>
          <li>Mobile Number</li>
          <li>UPI ID (Unified Payments Interface ID)</li>
        </ul>
        <h3>1.2. Payment Information:</h3>
        <p>Billing and payment information necessary for processing transactions, such as credit card details or other payment method information. Note that we do not store payment card information; it is securely processed by our payment service provider.</p>
        <h3>1.3. Usage Information:</h3>
        <p>Information about how you interact with our products and services, including the use of NFC cards and AI-generated reviews.</p>
        <h3>1.4. Google Business Link:</h3>
        <p>Link to your Google Business page. This information is used to generate reviews for user’s business page.</p>

        <h2>2. How We Use Your Information</h2>
        <p>We may use the personal information collected for the following purposes:</p>
        <h3>2.1. Transaction Processing:</h3>
        <p>To deliver NFC cards, and provide access to our AI-generated review service.</p>
        <h3>2.2. Customer Support:</h3>
        <p>To respond to your inquiries, requests, and provide customer support.</p>
        <h3>2.3. Personalization:</h3>
        <p>To personalise and improve the quality of AI-generated reviews based on the Google Business Link provided.</p>
        <h3>2.4. Communication:</h3>
        <p>To send transaction confirmations, updates, and important notices regarding our products and services.</p>
        <h3>2.5. Legal Compliance:</h3>
        <p>To comply with legal obligations, including tax reporting and fraud prevention.</p>

        <h2>3. Sharing Your Information</h2>
        <p>We may share your personal information with third parties in the following circumstances:</p>
        <h3>3.1. Service Providers:</h3>
        <p>We may share information with trusted third-party service providers who assist us in delivering our products and services.</p>
        <h3>3.2. Legal Compliance:</h3>
        <p>We may disclose information when required by law or to protect our rights, privacy, safety, or property, or that of others.</p>

        <h2>4. Data Security</h2>
        <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmitting data over the internet or electronic storage is entirely secure.</p>

        <h2>5. Your Choices</h2>
        <p>You may have certain rights regarding your personal information. You can:</p>
        <ul>
          <li>Review and update your personal information by logging into your account (if applicable).</li>
          <li>Contact us to request access to or correction of your personal information.</li>
          <li>Opt-out of receiving promotional communications from us by following the instructions provided in such communications.</li>
        </ul>

        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes either by posting a notice on our website or through other communication channels.</p>

        <h2>7. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:contactus@codentechnologies.com">contactus@codentechnologies.com</a></p>

        <p>By using our products and services, you consent to the practices described in this Privacy Policy.</p>
      </div>
      <Footer />
    </>
  )
          
  
}

export default PrivacyPolicy