import React from "react";
import styles from "./styles/productTile.module.css"
import Button from "./Button";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './productTile.css'

const ProductTile = ({ className, title, subTitle, discountedPrice, originalPrice, images, _id, amazonLink, onBuy, ...rest }) => {
    return (
        <div className={className} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", marginBottom: "30px" }}>
            <Carousel infiniteLoop>
                {images.map((ele, index) => (
                    <div>
                        <img
                            src={ele}
                            alt="Im"
                            style={{ marginLeft: '30px', paddingTop: "10px", width: '50%'}}
                            
                        />
                    </div>
                ))}

            </Carousel>
            <div style={{flex:1}}>
                <div className={styles.productTitle}>{title}</div>
                <div className={styles.productSubTitle}>{subTitle}</div>
                <div className={styles.price}>
                    <div className={styles.priceTitle}>₹{discountedPrice}</div>
                    <div className={styles.oldPriceTitle}>₹{originalPrice}</div>
                </div>
                <div className={styles.discountPriceTitle}>{(parseInt((originalPrice - discountedPrice) / originalPrice * 100))}% Off</div>
                <div><Button  onClick={()=>onBuy(_id, amazonLink)} title="Buy Now" /></div>
            </div>
        </div>
    )
}

export default ProductTile;