export const Card = [
  {
    id: 1,
    title: "Easy to get Review",
    para: "Customer can get review within 20 seconds",
  },
  {
    id: 2,
    title: "Customer reviews",
    para: "Reviews are tailored for Customer",
  },
  {
    id: 3,
    title: "Affordable prices",
    para: "Prices are so affordable! Costing no more than a pizza!",
  },
  {
    id: 4,
    title: "One QR for all social media",
    para: "Google, Instagram, Facebook, Swiggy, Zomato all in one QR",
  },
  {
    id: 5,
    title: "Insights!",
    para: "Can check all possible statistics in Customer's app",
  },
  {
    id: 6,
    title: "Unique reviews",
    para: "Almost every generated review will be unique and non repeated.",
  },
];

export const Review = [
  {
    id : 1,
    rating: 5,
    desc: `This ice cream parlour offers a wide variety of 40+ delicious ice cream
  flavours. The super combos they offer are irresistible and perfect for
  satisfying your sweet tooth cravings.`,
  },
  {
    id : 2,
    rating: 4,
    desc: `This ice cream parlour has a great ambiance and is a must-visit. The decor is
  vibrant and the ice cream flavours are delicious. Highly recommended for a
  sweet treat.`,
  },
  {
    id : 3,
    rating: 5,
    desc: `Ronkie Rich Ice Cream Parlour offers incredible super combos. Their
  delicious flavours and toppings are a must-try. Plus, their Ronkie Rich is
  simply amazing!`,
  },
  {
    id : 4,
    rating: 3,
    desc: `The tile designs and colour collections offered by this company are amazing.
  They have a wide variety to choose from, making it easy to find the perfect
  tiles for any project. Highly recommended!`,
  },
  {
    id : 5,
    rating: 4,
    desc: `The staff were incredibly friendly and helpful, making the tile selection
    process a breeze. The assortment of tile designs was impressive, and we
    found the perfect one for our kitchen renovation. Highly recommended!`,
  },
  {
    id : 6,
    rating: 5,
    desc: `The shop exceeded my expectations! Their unique gelato flavors are a taste
    bud adventure. The staff is always welcoming and ready to help you choose the 
    perfect treat. Highly recommended for a delightful dessert experience.`,
  },
  {
    id : 7,
    rating: 5,
    desc: `The shop exceeded my expectations! Their unique gelato flavors are a taste
    bud adventure. The staff is always welcoming and ready to help you choose the 
    perfect treat. Highly recommended for a delightful dessert experience.`,
  },
];

export const products =  [
  {
    id: 1,
    title: "PVC Review Card",
    description: "Introducing the Review Card – Your Ultimate Online Presence Booster! With just three simple steps, this powerful tool empowers your business to collect valuable customer reviews effortlessly. Elevate your online reputation and stand out from the competition. Take charge of your digital presence and watch your business flourish. Get the Review Card today and unlock the potential of positive customer feedback!",
    price: 499,
    discountPercentage: 12.96,
    rating: 4.69,
    stock: 94,
    brand: "Easy Revv",
    category: "PVC",
    thumbnail: "",
    images: [
      // "https://i.dummyjson.com/data/products/1/1.jpg",
      // "https://i.dummyjson.com/data/products/1/2.jpg",
      // "https://i.dummyjson.com/data/products/1/3.jpg",
      // "https://i.dummyjson.com/data/products/1/4.jpg",
      // "https://i.dummyjson.com/data/products/1/thumbnail.jpg"
    ]
  },
  {
    id: 2,
    title: "Coming Soon",
    description: "Coming Soon ...",
    price: 0,
    discountPercentage: 17.94,
    rating: 4.44,
    stock: 34,
    brand: "Easy Revv",
    category: "PVC",
    thumbnail: "https://img.freepik.com/free-vector/modern-coming-soon-poster-with-stay-tuned-message_1017-39310.jpg?w=740&t=st=1698936303~exp=1698936903~hmac=80a8d3be288abcc5a44e896a0df877221878c0cf306273099f17c1849f26592d",
    images: [
      // "https://i.dummyjson.com/data/products/2/1.jpg",
      // "https://i.dummyjson.com/data/products/2/2.jpg",
      // "https://i.dummyjson.com/data/products/2/3.jpg",
      // "https://i.dummyjson.com/data/products/2/thumbnail.jpg"
    ]
  },
  // {
  //   id: 3,
  //   title: "Samsung Universe 9",
  //   description: "Samsung's new variant which goes beyond Galaxy to the Universe",
  //   price: 1249,
  //   discountPercentage: 15.46,
  //   rating: 4.09,
  //   stock: 36,
  //   brand: "Samsung",
  //   category: "smartphones",
  //   thumbnail: "https://i.dummyjson.com/data/products/3/thumbnail.jpg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/3/1.jpg"
  //   ]
  // },
  // {
  //   id: 4,
  //   title: "OPPOF19",
  //   description: "OPPO F19 is officially announced on April 2021.",
  //   price: 280,
  //   discountPercentage: 17.91,
  //   rating: 4.3,
  //   stock: 123,
  //   brand: "OPPO",
  //   category: "smartphones",
  //   thumbnail: "https://i.dummyjson.com/data/products/4/thumbnail.jpg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/4/1.jpg",
  //     "https://i.dummyjson.com/data/products/4/2.jpg",
  //     "https://i.dummyjson.com/data/products/4/3.jpg",
  //     "https://i.dummyjson.com/data/products/4/4.jpg",
  //     "https://i.dummyjson.com/data/products/4/thumbnail.jpg"
  //   ]
  // },
  // {
  //   id: 5,
  //   title: "Huawei P30",
  //   description: "Huawei’s re-badged P30 Pro New Edition was officially unveiled yesterday in Germany and now the device has made its way to the UK.",
  //   price: 499,
  //   discountPercentage: 10.58,
  //   rating: 4.09,
  //   stock: 32,
  //   brand: "Huawei",
  //   category: "smartphones",
  //   thumbnail: "https://i.dummyjson.com/data/products/5/thumbnail.jpg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/5/1.jpg",
  //     "https://i.dummyjson.com/data/products/5/2.jpg",
  //     "https://i.dummyjson.com/data/products/5/3.jpg"
  //   ]
  // },
  // {
  //   id: 6,
  //   title: "MacBook Pro",
  //   description: "MacBook Pro 2021 with mini-LED display may launch between September, November",
  //   price: 1749,
  //   discountPercentage: 11.02,
  //   rating: 4.57,
  //   stock: 83,
  //   brand: "Apple",
  //   category: "laptops",
  //   thumbnail: "https://i.dummyjson.com/data/products/6/thumbnail.png",
  //   images: [
  //     "https://i.dummyjson.com/data/products/6/1.png",
  //     "https://i.dummyjson.com/data/products/6/2.jpg",
  //     "https://i.dummyjson.com/data/products/6/3.png",
  //     "https://i.dummyjson.com/data/products/6/4.jpg"
  //   ]
  // },
  // {
  //   id: 7,
  //   title: "Samsung Galaxy Book",
  //   description: "Samsung Galaxy Book S (2020) Laptop With Intel Lakefield Chip, 8GB of RAM Launched",
  //   price: 1499,
  //   discountPercentage: 4.15,
  //   rating: 4.25,
  //   stock: 50,
  //   brand: "Samsung",
  //   category: "laptops",
  //   thumbnail: "https://i.dummyjson.com/data/products/7/thumbnail.jpg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/7/1.jpg",
  //     "https://i.dummyjson.com/data/products/7/2.jpg",
  //     "https://i.dummyjson.com/data/products/7/3.jpg",
  //     "https://i.dummyjson.com/data/products/7/thumbnail.jpg"
  //   ]
  // },
  // {
  //   id: 8,
  //   title: "Microsoft Surface Laptop 4",
  //   description: "Style and speed. Stand out on HD video calls backed by Studio Mics. Capture ideas on the vibrant touchscreen.",
  //   price: 1499,
  //   discountPercentage: 10.23,
  //   rating: 4.43,
  //   stock: 68,
  //   brand: "Microsoft Surface",
  //   category: "laptops",
  //   thumbnail: "https://i.dummyjson.com/data/products/8/thumbnail.jpg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/8/1.jpg",
  //     "https://i.dummyjson.com/data/products/8/2.jpg",
  //     "https://i.dummyjson.com/data/products/8/3.jpg",
  //     "https://i.dummyjson.com/data/products/8/4.jpg",
  //     "https://i.dummyjson.com/data/products/8/thumbnail.jpg"
  //   ]
  // },
  // {
  //   id: 9,
  //   title: "Infinix INBOOK",
  //   description: "Infinix Inbook X1 Ci3 10th 8GB 256GB 14 Win10 Grey – 1 Year Warranty",
  //   price: 1099,
  //   discountPercentage: 11.83,
  //   rating: 4.54,
  //   stock: 96,
  //   brand: "Infinix",
  //   category: "laptops",
  //   thumbnail: "https://i.dummyjson.com/data/products/9/thumbnail.jpg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/9/1.jpg",
  //     "https://i.dummyjson.com/data/products/9/2.png",
  //     "https://i.dummyjson.com/data/products/9/3.png",
  //     "https://i.dummyjson.com/data/products/9/4.jpg",
  //     "https://i.dummyjson.com/data/products/9/thumbnail.jpg"
  //   ]
  // },
  // {
  //   id: 10,
  //   title: "HP Pavilion 15-DK1056WM",
  //   description: "HP Pavilion 15-DK1056WM Gaming Laptop 10th Gen Core i5, 8GB, 256GB SSD, GTX 1650 4GB, Windows 10",
  //   price: 1099,
  //   discountPercentage: 6.18,
  //   rating: 4.43,
  //   stock: 89,
  //   brand: "HP Pavilion",
  //   category: "laptops",
  //   thumbnail: "https://i.dummyjson.com/data/products/10/thumbnail.jpeg",
  //   images: [
  //     "https://i.dummyjson.com/data/products/10/1.jpg",
  //     "https://i.dummyjson.com/data/products/10/2.jpg",
  //     "https://i.dummyjson.com/data/products/10/3.jpg",
  //     "https://i.dummyjson.com/data/products/10/thumbnail.jpeg"
  //   ]
  // },
]

export const termsAndConditions = {
  updatedAt : "05-10-2022",
  title : "Terms and Conditions for NFC Card and Review Service",
  info : [
    {
      id : 1,
      title : "Acceptance of Terms",
      firstPara : "By purchasing and using our digital NFC card and AI-generated review service, you agree to comply with these terms and conditions. If you do not agree with these terms, please do not use our products or services."
    },
    {
      id : 2,
      title : "Description of Products and Services",
      firstPara : "",
      subItems : [
        {
          id : 2.1,
          heading : "Digital NFC Cards", 
          para : "We offer digital NFC cards for sale at the price mentioned in the Manufacturer's Recommended Price (MRP). Each card is to be used for the intended purpose of accessing review services."
        },
        {
          id : 2.2,
          heading : "AI-Generated Reviews", 
          para : "When you scan the NFC card using a compatible device, you will receive an AI-generated review free of charge, as of the date of this agreement. Please note that we reserve the right to charge for this service in the future, and any such changes will be communicated to users in advance"
        },
      ] 

    },
    {
      id : 3,
      title : "Payment and Pricing",
      firstPara : " The pricing for our digital NFC cards is as specified in the MRP. Payment is required before we provide you with the NFC card."
    },
    {
      id : 4,
      title : "User Responsibilities",
      firstPara : "",
      subItems : [
        {
          id : 4.1,
          heading : "", 
          para : "You are responsible for using the digital NFC card in accordance with the intended purpose and any applicable laws and regulations."
        },
        {
          id : 4.2,
          heading : "", 
          para : " You are responsible for any device and connectivity requirements necessary to access the AI-generated reviews."
        },
      ] 
    },
  ]
}

export const faqs  = [
  {
    id :1,
    question : "How long does the setup take ?",
    answer: "The setup typically takes a 2-3 minutes and will be activated instantly once the setup is done",
    isOpen : false
  },
  {
    id :2,
    question : "What is the keyword ?",
    answer: "keywords that describes your business, your customer selects selects the keyword you added",
    isOpen : false
  },
  {
    id :3,
    question : "Can I change keywords once I add them ?",
    answer: "Absolutely, you can change it any times if you want",
    isOpen : false
  },
  {
    id :4,
    question : "Do I get insights ?",
    answer: "Yes you do get insights on EasyRevv application",
    isOpen : false
  },
  // {
  //   id :5,
  //   question : "Sample Question",
  //   answer: "Normally takes less than 2 minutes",
  //   isOpen : false
  // },
]